$(document).ready(() => {
    let firstRoomUrl = route('frontend.event.communications.getAll', [eventSlug, rooms[0].id]);

    // Constructing rooms buttons & their correspondant
    // commuincation fetch-url
    let roomKeyNames = "";
    let roomButtons = {};
    rooms.map((room) => {
        roomKeyNames = roomKeyNames.concat(room.id, " ");
        roomButtons[room.id] = {
            text: room.name,
            click: function () {
                calendar.getEventSources()[0].remove();
                calendar.addEventSource(route('frontend.event.communications.getAll', [eventSlug, room.id]))
            }
        }
    })

    var calendarEl = document.getElementById('calendar');
    if (calendarEl) {
        calendar = new FullCalendar.Calendar(calendarEl, {
            contentHeight: 800,
            locale: 'fr',
            timeZone: timezone,
            validRange: {
                start: eventStartDate,
                end: eventEndDate
            },
            initialDate: eventStartDate,
            initialView: 'eventDays',
            noEventsContent: 'Pas de communication à afficher',
            selectable: true,
            editable: true,
            slotDuration: '00:20:00',
            slotMinTime: new Date(eventStartDate).toLocaleTimeString('en-GB'),
            slotLabelFormat: {
                hour: 'numeric',
                minute: '2-digit',
                hour12: false
            },
            slotEventOverlap: false,
            selectOverlap: true,
            initialEvents: firstRoomUrl,
            headerToolbar: {
                left: roomKeyNames.trim(),
                center: 'title',
                right: 'eventDays oneDay today prev,next'
            },
            views: {
                eventDays: {
                    type: 'list',
                    buttonText: 'Tous les jours',
                    duration: {
                        days: eventDays
                    }
                },
                oneDay: {
                    type: 'list',
                    buttonText: 'Par jour',
                }
            },
            buttonText: {
                today: "Aujourd'hui",
                // prev: "Précédent",
                // next: "Suivant",
            },
            customButtons: roomButtons,
            eventDidMount: function (info) {
                let commSpeakers = info.event.extendedProps.speakers;
                new bootstrap.Popover(info.el, {
                    title: `
                        <b class="event-color-1">Titre : </b>${info.event.title}</br>
                        ${
                            info.event.extendedProps.session_title != null
                            ? `<b class="event-color-1">Session : </b>${info.event.extendedProps.session_title}</br>` : ''
                        }
                        ${
                            info.event.extendedProps.moderators != null
                            ? `<b class="event-color-1">Modérateurs : </b>${info.event.extendedProps.moderators ?? ''}</br>`: ''
                        }
                        ${
                            info.event.extendedProps.hasOwnProperty('speakers') && info.event.extendedProps.speakers.length > 0
                            ? `<b class="event-color-1">Orateurs : </b> ${commSpeakers.map((speaker) => speaker.full_name + ', ').join("")} </br>`: ''
                        }
                    `,
                    placement: info.event.extendedProps.hasOwnProperty('moderators')
                        ? 'right'
                        : 'top', // communicaiotn -> right, session -> top
                    trigger: 'hover',
                    container: 'div#calendar',
                    html: true,
                    customClass: 'text-left'
                });
            },
            eventClick: function (info) {
                $('#communicationDetailsModal').modal('show');
                $('#title', '#communicationDetailsModal').text(info.event.title);
                $('#startDate', '#communicationDetailsModal').text(info.event.extendedProps.formated_start_date);
                $('#endDate', '#communicationDetailsModal').text(info.event.extendedProps.formated_end_date);
                $('#type', '#communicationDetailsModal').text(info.event.extendedProps.type_label);
                $('#session', '#communicationDetailsModal').text(info.event.extendedProps.session_title);
                info.event.extendedProps.speakers.map((speaker) => {
                    $('#speakers', '#communicationDetailsModal').append(
                        speaker.full_name + ', '
                    );
                })
            },
        });

        calendar.render();

        $('#communicationDetailsModal').on('shown.bs.modal', function () {
            // Remove all stuck popovers
            $('.popover').remove()
        })

        $('#communicationDetailsModal').on('hidden.bs.modal', function () {
            // Remove old data
            $('span', this).empty()
        })

        // Adding some styling for the header elements & buttons
        $("#calendar").find('.fc-header-toolbar').addClass('text-center');
        $("#calendar .fc-header-toolbar .fc-button").addClass('mb-2 mb-lg-0');
        $("#calendar .fc-header-toolbar").find('.fc-toolbar-chunk:eq(1)').addClass('ms-4 ms-md-0');
        $(".fc-toolbar-chunk", "#calendar .fc-header-toolbar").find('button:first').addClass('visited');
        $("#calendar .fc-list-day-text").css('ms-4 ms-md-0');
        $("#calendar .fc-toolbar-title").addClass('event-color-1');

        $("#calendar .fc-header-toolbar .fc-button").on('click', function() {
            $(this).addClass('mb-2 mb-lg-0 visited');
            $(this).closest(".fc-toolbar-chunk").find('.fc-button').not(this).each(function () {
                $(this).removeClass('visited');

                // if responsive classes were removed, add them
                if (! $(this).hasClass('mb-2 mb-lg-0')) {
                    $(this).addClass('mb-2 mb-lg-0')
                }
            });
        });
    }

});
